/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { H2 } from 'src/components/atoms/text'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { PrismicJobPostDataType, PrismicImageType } from 'root/graphql-types'
import { motion } from 'framer-motion'
import {
  AnimationScrollYFix,
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
  STAGGER_TIME,
} from 'src/animations'
import SEO from 'src/components/organisms/SEO'
import { setActiveLink } from 'src/components/organisms/NavBar/NavBarDesktop'
import RichText from 'src/components/organisms/RichText'
import { replaceHtml } from 'src/utils/htmlReactParser'

type JobPostProps = {
  pageContext: PrismicJobPostDataType & {
    seo_image_1200x1200: PrismicImageType
    alternate_languages: any
    lang: any
    content: any
    post_title: any
  }
  path: string
}

const JobPost: FC<JobPostProps> = ({ pageContext, path }) => {
  const { post_title } = pageContext

  const postTitleHtml = replaceHtml(post_title?.html || '', 'p', 'div')

  setActiveLink('jobs')
  return (
    <div>
      <SEO
        title={pageContext?.seo_title}
        image={pageContext?.seo_image_1200x1200?.fluid?.src}
        description={pageContext?.seo_description}
        path={path}
        alternate_languages={pageContext.alternate_languages}
        lang={pageContext.lang}
      />
      <AnimationScrollYFix />
      <motion.div {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER })}>
        <H2 sx={{ mt: ml('s', 'l'), mb: ml('m', 'xl') }}>{postTitleHtml}</H2>
      </motion.div>
      <motion.div
        {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME })}
      >
        <RichText content={pageContext?.content} />
      </motion.div>
    </div>
  )
}

export default JobPost
